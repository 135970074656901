<template>
  <v-container
    class="d-flex flex-column justify-start"
    fluid
  >
    <v-row>
      <v-col class="pr-0 pl-3">
        <RunEditor
          v-if="selectedItem"
          :milestones="activeMilestones"
          :item="selectedItem"
          @update-filter="updateItem"
          @update-tags="updateTags"
          @update-milestones="updateMilestones"
        />
        <CaseView
          v-model="runCases"
          :case-type="'EditCase'"
          :back-title="$t('testruns.create_testrun.back_to_testrun')"
        >
          <template v-slot:action>
            <v-btn
              color="primary"
              depressed
              class="f-color-white ml-2 btn-theme text-capitalize"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              height="38px"
              @click="saveTestRuns"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </CaseView>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RunEditor from '@/components/TestRuns/RunEditor.vue';
import CaseView from '@/views/Tests/Runs/Create/RunAddCaseView';
import { mapGetters, mapState ,createNamespacedHelpers} from 'vuex';
import makeMilestoneService from '@/services/api/milestone'
const { mapState: mapProjectState, mapMutations, mapActions } = createNamespacedHelpers('run');
import { showSuccessToast,showAlertToast, showErrorToast } from '@/utils/toast';
export default {
  name: 'TestRunEdit',
  components: {
    RunEditor,
    CaseView,
  },
  props: ['customItem'],
  data() {
    return {
      selectedItem: null,
      runCases: [],
      milestones: [],
      loading: false,
      selectedTags: [],
      selectedMilestones: [],
    };
  },
  computed: {
    ...mapState('run', ['items']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    activeMilestones() {
      return this.milestones.filter(milestone => milestone.archivedAt === null && milestone.deletedAt === null)
      .map(milestone => ({ name: milestone.name, uid: milestone.uid }));
    },
  },
  async created() {
    const handle = this.$route.params.handle;
    const projectKey = this.$route.params.key;
    const runId = this.$route.params.id;
    await this.getProjectRun(     
      handle,
      projectKey,
      runId
    );
  },
  methods: {
    ...mapActions(['getTestRunById', 'updateTestRun']),
    updateItem(item){
      this.selectedItem = item
    },
    updateTags(tags){
      this.selectedTags = tags
    },
    updateMilestones(milestones){
      this.selectedMilestones = milestones
    },
    async getProjectRun(handle, projectKey, runId) {
      await this.getTestRunById({ handle, projectKey ,runId}).then( response => {
          this.selectedItem = response.data.run[0]
          this.selectedTags = response.data.run[0].customFields.tags
          this.runCases = response.data.runCases
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('testruns.edit_testrun.not_found'))
          this.$router.push({name: 'Runs'})
        })
    },
    saveTestRuns() {
      if(this.selectedItem){
        const selectedCases = this.runCases.map(item => item.testCaseRef)
        const payload = {
          ...this.selectedItem,
          cases: selectedCases,
          customFields: {
            ...this.selectedItem.customFields,
            tags: this.selectedTags,
            caseCount: selectedCases.length
          },
        }
        let handle = this.$route.params.handle;
        
        this.updateTestRun({ swal: this.$swal, handle: handle, projectKey: this.$route.params.key, uid: this.$route.params.id, payload})
          .then(() => {
            this.$router.push({
              name: 'Runs', params: {
                handle: handle,
                key: this.$route.params.key
              }
            });
          })
          .catch((error) => {
            console.error('Failed to Update Test Run:', error);
          });
      }
    },
    async getMilestones() {
      this.loading = true;
      try {
        const milestoneSrvice = makeMilestoneService(this.$api);
        const response = await milestoneSrvice.getMilestones(this.$route.params.handle, this.$route.params.key);
        this.milestones = response.data.milestones;
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        this.milestones = []; // Reset to empty array in case of error
      } finally {
        this.loading = false;
      }
    },
    // Refreshes all data
    async refreshData() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.getProjectRun(
        handle,
        projectKey,
        runId
      );
    },
  },
  async mounted(){
    await this.getMilestones()
  }
};
</script>